export const formId = "8f098f70-e3cc-48ce-a53e-79e6ade078da";
// eslint-disable-next-line import/prefer-default-export
export const newYorkHero = {
  title: "More than a point-of-sale. A better way to run your business",
  heroImg: "Ohio.png",
  subtext:
    "As an association member, you can choose how you want to save with SpotOn. Either get a credit starting at $500 towards your first POS station or receive a free consultation, set up, and trial of SpotOn Reserve or SpotОn labor management solution.",
  imageClassName: "fixed-height-600 top-adjustement",
  PrimaryCta: {
    ctaTitle: "Get a demo",
    ctaTarget: "/associations/demo",
  },
  video: {
    videoBtn: "Play video",
    posterWrapperClass: "",
    posterImg: "hero-video-image.png",
    videoUrlId: "hLlIUkFESiw",
  },
};

export const newYorkSeal = {
  title: "New York State Restaurant Association:",
  img: "NYRA_logo.png",
  text: "SpotOn Named a Preferred Technology Partner of the New York State Restaurant Association",
  url: "https://spoton.com/blog/spoton-named-preferred-technology-partner-new-york-state-restaurant-association/",
};

export const newYorkLargeFeatures = {
  featureBlocks: [
    {
      blockTitle: "Online Ordering",
      blockImg: "large-phone.png",
      blockSubTitle:
        "With digital solutions for take-out, delivery, and on-premise dining, SpotOn helps increase throughput to boost revenue with significant cost savings compared to third-party services.",
      blockList: [
        "Online ordering from your website",
        "Mobile dine-in ordering",
        "SpotOn Delivery, powered by DoorDash",
        "E-Gift cards",
        "Direct POS integration",
        "Own your guest's data",
      ],
      forceReverse: false,
      id: "oo",
      ctaPrime: {
        ctaTitle: "Get a demo",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Reservations & Waitlisting",
      blockSubTitle:
        "Book more reservations, serve more guests, and easily comply with contact tracing requirements - all while delivering a VIP guest experience with the SpotOn Reserve front-of-house solution.",
      blockImg: "reservation_confirmed.png",
      blockList: [
        "24-hour online booking",
        "Waitlist & real-time notifications",
        "Server & table management",
        "Reserve with Google listing",
        "Standalone solution or POS integration",
        "Optional vaccination status checkbox",
      ],
      id: "rct",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Get a demo",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Contactless Payments",
      blockImg: "contactless-payments.png",
      blockSubTitle:
        "Create an end-to-end contactless experience to keep your customers safe and the checkout process moving faster than ever before.",
      blockList: [
        "Handheld POS",
        "QR code on receipt",
        "Credit card terminal",
        "Consumer facing displays",
        "Mobile dine-in ordering",
        "Mobile card readers",
      ],
      id: "cp",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Get a demo",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Marketing & Loyalty",
      blockImg: "Marketing.png",
      blockSubTitle:
        "Improve customer loyalty, manage your online reputation, and drive repeat visits with fully-integrated customer engagement tools designed to help your business grow.",
      blockList: [
        "Marketing campaigns",
        "Review management",
        "Digital loyalty",
        "Online dashboard",
        "Mobile dashboard app",
        "Guest and campaign analytics",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Get a demo",
        ctaTarget: "/associations/demo",
      },
    },
  ],
};
export const newYorkRestaurantTypes = [
  {
    content: "Casual Full-Service",
    desc: "Let your customers order and pay their way with a flexible configuration that makes sense for your restaurant.",
    img: "casual_fs.png",
  },
  {
    content: "Bars & Nighclubs",
    desc: "Save time with lightning-fast performance, an intuitive interface, and the ability to remotely monitor bar stations.",
    img: "bars_clubs.png",
  },
  {
    content: "Quick Service",
    desc: "Keep the checkout line moving fast and stand out from your competitors with your own mobile app for customers.",
    img: "quick-serve.png",
  },
  {
    content: "Enterprise",
    desc: "Monitor and manage multiple location with real-time reporting and instant updates across all location.",
    img: "enterprise.png",
  },
  {
    content: "Pizzeria",
    desc: "Easily create and manage your customer database and offer multiple ways for customers to order and pay.",
    img: "pizzeria.png",
  },
  {
    content: "Fine Dining",
    desc: "SpotOn’s Restaurant POS experts will work closely with you to build and configure a point-of-sale solution that is tailor-made to streamline operations, so you can offer the best fine dining experience possible.",
    img: "fine-dine.png",
  },
];

export const newYorkTestimonialsData = [
  {
    testimonial: `“SpotOn has excellent service. When we call they are always available & the system is very user friendly.”`,
    client: `Tom Coohill`,
    company: `Coohils`,
    imageOne: `coohills3.jpg`,
    imageTwo: `coohils2.jpg`,
    imageThree: `coohils1.jpg`,
  },
  {
    testimonial: `“SpotOn and Anthony have been great to work with thus far. They show up when they need to and the install was perfect.”`,
    client: `Chase Campbell`,
    company: `Mile High Spirits`,
    imageOne: `mh1.png`,
    imageTwo: `mh3.jpeg`,
    imageThree: `mh2.png`,
  },
  {
    testimonial: `“Several of our member businesses have experienced the simplicity and growth that come along with SpotOn's services.”`,
    client: `John Wetenkamp `,
    company: `Lodo District  `,
    imageOne: `lodo1.jpg`,
    imageTwo: `lodo2.jpg`,
    imageThree: `lodo3.jpg`,
  },
  {
    testimonial: `“Easy to learn system both Front of House & Back of House, can’t wait to use the integration with my payroll.”`,
    client: `Judson Dymond`,
    company: `Sportsbook Bar & Grill`,
    imageOne: `sportsbook1.jpg`,
    imageTwo: `sportsbook2.jpg`,
    imageThree: `sportsbook3.jpg`,
  },
];

export const newYorkIconTemplate = [
  {
    content: " Intuitive front-of-house",
    desc: "Streamline operations, accelerate growth, and provide guests with a first-class experience, in-house or online",
    img: "book-open.png",
  },
  {
    content: "Powerful back-of-house",
    desc: "Boost efficiency with kitchen display screens and multi-language tickets, while saving money with payroll integration",
    img: "urgent.png",
  },
  {
    content: "Handheld POS & mobile ordering",
    desc: "Cut the wires and engage guests with contactless, online ordering or take-out, delivery, and dine-in options",
    img: "calc.png",
  },
  {
    content: "Elite guest data & reporting",
    desc: "Stay informed with the free mobile reporting app to access guest data anywhere, anytime",
    img: "pie-chart.png",
  },
  {
    content: "Third-party integrations",
    desc: "Unlock more potential with powerful built-in tools and compatibility with many third-party services",
    img: "git-merge.png",
  },
  {
    content: "In-person installation & training",
    desc: "Gain confidence with professional installation, face-to-face staff training, and around-the-clock tech support at no extra cost",
    img: "award.png",
  },
];

export const newYorkXL = {
  mainTitle: "Providing businesses with in-person & local support since 2013",
  subtext:
    "You deserve to do business with someone you can look in the eye, with a proven track record of helping other local businesses. That’s why SpotOn has a dedicated, local team of professionals at your service, backed by 24/7 technical support only a phone call away.",
  phoneNumber: "+18882246506",
  phoneText: "(888) 224-6506",
  email: "hospitality@spoton.com",
};

export const newYorkWhiteGlove = {
  mainTitle: "We are there for you",
  title: "Service and Support",
  content:
    "Get the local in-person support your business deserves, along with around-the-clock technical support - included at no extra cost.",
  contentList: [
    "In-person hardware installation",
    "Face-to-face staff training",
    "Ongoing virtual training",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Get a demo",
    ctaTarget: "/associations/demo",
  },
};

export const newYorkLogos = [
  "coohills_logo.png",
  "LoDo-logo.png",
  "Sportsbook-logo.png",
  "mile-high-logo.png",
];
